import { Link } from "react-router-dom";
import styled from "styled-components";
import { STYLE_CONSTANTS } from "../styles/styleConstants";

const FooterPosition = styled.div`
  padding: 10px; /* Adjust padding as needed */
  height: ${STYLE_CONSTANTS.footerSizeValue}vh;
  background-color: #f0f0f0; /* Optional: Add a background color for visibility */
`;

const IntegrityLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Footer() {
  return (
    <FooterPosition>
      <IntegrityLink>
        <div>
          Von <Link to="/#">budget-drive.com</Link>
        </div>
      </IntegrityLink>
    </FooterPosition>
  );
}
export default Footer;
