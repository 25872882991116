import React from "react";
import styled from "styled-components";
import EmblaCarousel from "../../widgets/carousel/EmbelaCarousel";
import { Row, Title } from "../../../styles/StyledElements";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2; /* Take up 2/3 of the row's space */
`;
const CarouselContainer = styled.div`
  flex: 1; /* Take up 1/3 of the row's space */
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;
const Text = styled.p`
  text-align: justify;
  padding-bottom: 4px;
`;
const TextContainer = styled.div`
  padding-left: 20px;
`;

function FeatureSlide({ feature }) {
  let descriptionLines = feature.description.split("\n");
  return (
      <Row>
        <Column>
          <Title>{feature.title}</Title>
          {descriptionLines.map((line, i) => {
            line = line.trim();
            let startsWithNumber = /^\d/.test(line);
            if (startsWithNumber) {
              const match = line.match(/^(\d+)\.\s*(.*)$/);
              const number = match[1];
              const text = match[2];
              return (
                <TextContainer key={i}>
                  <Text>
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {number}.{" "}
                    </span>
                    {text}
                  </Text>
                </TextContainer>
              );
            }
            return <Text key={i}>{line}</Text>;
          })}
        </Column>
        <CarouselContainer>
          <EmblaCarousel slides={feature.images} />
        </CarouselContainer>
      </Row>
  );
}

export default FeatureSlide;
