import styled from "styled-components";
import { FEATURES } from "../../../data/featureDescriptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLORS } from "../../../styles/colors";
import { Link as LinkScrollTo } from "react-scroll";
import { STYLE_CONSTANTS } from "../../../styles/styleConstants";

const Layout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  align-items: center;
  justify-content: center;

  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {
    grid-template-columns: repeat(1, 1fr);
  }
  padding: 15px;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.headerWhiteSmoke};
  padding: 20px;
  text-align: center;
  gap: 5px;
  width: 200px;
  height: 200px;
  align-items: center;
  justify-content: center;
  border-radius: 10%;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${COLORS.hoverWhiteSmoke};
  }
`;

const Icon = styled.div``;

const Title = styled.div`
  font-weight: bold;
  color: black;
`;
const Description = styled.div`
  color: black;
`;

export default function InfoFeatures() {
  return (
    <Layout>
      {Object.keys(FEATURES).map((key, i) => {
        const feature = FEATURES[key];
        return (
          <LinkScrollTo key={key} to={feature.title} smooth={true} duration={STYLE_CONSTANTS.scrollDuration}>
            <GridItem>
              <Icon>
                <FontAwesomeIcon
                  icon={feature.icon}
                  color={COLORS.purple}
                  style={{
                    border: "2px solid",
                    padding: "5px",
                    borderRadius: "100%",
                    width: "20px",
                    height: "20px",
                  }}
                />
              </Icon>
              <Title>{feature.title}</Title>
              <Description>{feature.shortDescription}</Description>
            </GridItem>
          </LinkScrollTo>
        );
      })}
    </Layout>
  );
}
