import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { COLORS } from '../../styles/colors';
import { animateScroll as scroll } from 'react-scroll';
import { STYLE_CONSTANTS } from '../../styles/styleConstants';

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 5vh;
  left: 50%;
  transform: translate(-50%, -5%);
  background-color: ${COLORS.headerWhiteSmoke}; 
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: ${COLORS.hoverWhiteSmoke};
    transform: translate(-50%, -5%) scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

 .icon {
    margin-right: 5px;
  }
`;


const ScrollToTop = () => {
    const [showButton, setShowButton] = useState(false);
  
    const handleScroll = () => {
        const viewportHeight = window.innerHeight;
      if (window.scrollY > viewportHeight) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
  
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({ duration: STYLE_CONSTANTS.scrollDuration, smooth: true });
    };
  
    return (
      <div>
        {showButton && (
          <BackToTopButton onClick={scrollToTop}>
            <FontAwesomeIcon icon={faArrowUp} className="icon" />
            Zurück zum Seitenanfang
          </BackToTopButton>
        )}
      </div>
    );
  };
  
  export default ScrollToTop;