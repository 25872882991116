import styled from "styled-components";
import { Title } from "../../../styles/StyledElements";
import MobileNav from "./MobileNav";
import { STYLE_CONSTANTS } from "../../../styles/styleConstants";
import Logo from "../Logo";
import React, { useState, useEffect } from 'react';

const HeaderBackgroundDiv = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  z-index: 10;
  flex-direction: column;
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${STYLE_CONSTANTS.headerSize()};
  ${STYLE_CONSTANTS.headerStyleConstants}
  padding-left: 8px;
`;

const StyledA = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center; 
  justify-content: center; 
  color: inherit; 
`;

const LogoDiv = styled.div`
  position: fixed;
  top: 0;
  height: ${STYLE_CONSTANTS.headerSize()};
  width: ${STYLE_CONSTANTS.headerSize()};
  left: 2vw;
  transition: all 0.3s ease-in-out; /* Add transition for smooth changes */

  ${HeaderBackgroundDiv}:not(.scrolled) & {
    opacity: 1;
    transform: translateY(0); /* Initial position */
    }
    
    ${HeaderBackgroundDiv}.scrolled & {
    opacity: 0;
    transform: translateY(-20px); /* Slide up when scrolled */
  }
`;
const TitleDiv = styled.div`
  position: fixed;
  left: 2vw;
  height: ${STYLE_CONSTANTS.headerSize()}
  top: 0;
  transform: translateY(20px); /* Initial position */
  opacity: 0;
  transition: all 0.3s ease-in-out; /* Add transition for smooth changes */

  ${HeaderBackgroundDiv}.scrolled & {
    opacity: 1;
    transform: translateY(0); /* Initial position */
  }
`;

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  return (
    <HeaderBackgroundDiv className={isScrolled? 'scrolled' : ''}>
      <HeaderDiv>
        <StyledA href="/">
          <LogoDiv>
            <Logo>Logo</Logo>
          </LogoDiv>
          <TitleDiv>
            <Title>Budget Drive</Title>
          </TitleDiv>
        </StyledA>
      </HeaderDiv>
      <MobileNav></MobileNav>
    </HeaderBackgroundDiv>
  );
}
export default Header;
