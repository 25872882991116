import React from 'react';
import { HomeLayout, Title } from '../../styles/StyledElements';

function UserDeletionGuidePage() {
 
    return (
        <HomeLayout>
            <Title>Konto Löschung Anleitung</Title>
            <p>Hier sind die Schritte zum Löschen eines Kontos: </p>
            <br />
            <p>
                <strong>Per E-Mail:</strong> Senden Sie uns eine E-Mail an taschner.berenguer@gmail.com und wir werden Ihre persönlichen Daten so schnell wie möglich entfernen.
            </p>
            <br />
            <p>
                <strong>In der App</strong>: Zugriff auf das Menü: Öffnen Sie das Menü, indem Sie auf das Burger-Symbol unten rechts auf dem Bildschirm tippen. Profil bearbeiten: Wählen Sie <code>Profil bearbeiten</code> aus den Menüoptionen. Benutzer löschen: Tippen Sie auf <code>Benutzer löschen</code> und bestätigen Sie, falls Sie Ihr Konto wirklich löschen möchten.
            </p>
            <br />
            <p>
                <strong>Beachten Sie bitte:</strong> Wenn Ihr Konto 24 Stunden lang inaktiv bleibt (z.B. kein Login), wird es automatisch gelöscht.
            </p>
        </HomeLayout>
    );
}

export default UserDeletionGuidePage;