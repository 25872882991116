// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
a,
input,
button {
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.015rem;
  margin: 0;
  padding: 0;
}

a {
  color: blue;
}

input,
button {
  letter-spacing: 0.24px; /* Adjust letter-spacing for input and button if needed */
}

/* Define the media query */
@media (max-width: 735px) {
  :where(p, a, input, button) {
    font-size: 0.6rem; /* Adjust font size for smaller screens */
  }
}

section {
  display: grid;
  place-items: center;
  align-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;;EAEE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;;;;EAIE,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;;EAEE,sBAAsB,EAAE,yDAAyD;AACnF;;AAEA,2BAA2B;AAC3B;EACE;IACE,iBAAiB,EAAE,yCAAyC;EAC9D;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB","sourcesContent":["body,\nhtml {\n  margin: 0;\n  padding: 0;\n}\n\nbody {\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\",\n    \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\np,\na,\ninput,\nbutton {\n  font-size: 1rem;\n  font-weight: 400;\n  letter-spacing: 0.015rem;\n  margin: 0;\n  padding: 0;\n}\n\na {\n  color: blue;\n}\n\ninput,\nbutton {\n  letter-spacing: 0.24px; /* Adjust letter-spacing for input and button if needed */\n}\n\n/* Define the media query */\n@media (max-width: 735px) {\n  :where(p, a, input, button) {\n    font-size: 0.6rem; /* Adjust font size for smaller screens */\n  }\n}\n\nsection {\n  display: grid;\n  place-items: center;\n  align-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
