import React from "react";

import FeatureSlide from "./page-sections/FeatureSlide";
import { FEATURES } from "../../data/featureDescriptions";
import LandingPage from "./page-sections/LandingPage";
import { HomeLayout } from "../../styles/StyledElements";
import ScrollToTop from "../widgets/BackToTop";
import InfoFeatures from "./page-sections/InfoFeatures";
import { Element } from "react-scroll";
import { trackPageLoad } from "../../utils/tracking";

class HomePage extends React.Component {

  
  render() {
    trackPageLoad("LOADED");
    return (
      <HomeLayout>
        <section>
          <LandingPage />
        </section>
        <section>
          <InfoFeatures />
        </section>
        {Object.keys(FEATURES).map((key, i) => {
          const feature = FEATURES[key];
          return (
            <Element key={i} name={feature.title}>
              <section style={{margin: "10vh 0vh"}}>
                <FeatureSlide feature={feature} />
              </section>
            </Element>
          );
        })}
        <ScrollToTop />
      </HomeLayout>
    );
  }
}

export default HomePage;
