import { useRef, useEffect, useState } from 'react';

export const useAutoplayOnViewport = (options) => {
  const carouselRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsInView(entry.isIntersecting);
    }, options);

    const currentCarouselRef = carouselRef.current; // Capture the current value

    if (currentCarouselRef) {
      observer.observe(currentCarouselRef);
    }

    return () => {
      if (currentCarouselRef) {
        observer.unobserve(currentCarouselRef);
      }
    };
  }, [options, carouselRef]); // Include carouselRef in dependencies

  return { carouselRef, isInView };
};