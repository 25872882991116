import React, { useState } from 'react';
import MultiLineTextField from '../widgets/MultiLineTextField';
import Button from '../widgets/Button';
import styled from 'styled-components';
import showError from '../widgets/Popup';
import axios from 'axios';
import { AES, mode, pad, enc} from 'crypto-js'; 
import { BACKEND_URL, CRYPTOK } from '../../utils/EnvironmentVariables';
import { HomeLayout, Title } from '../../styles/StyledElements';

const Padding = styled.div`
 padding-top: ${props => `${props.$top}px`};
`;
// const topPaddingSameComponent = 20
const topPaddingDifferentComponent = 30

function PasswordResetPage() {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    let initialFieldProperties = [
        {
            name:"password",
            type: "password",
            placeholder: "Neue Passwort",
            handleChange: (e) => {console.log(e)},
            text: ""
        },
        {   
            name: "password confirmation",
            type: "password",
            placeholder: "Passwortbestätigung",
            handleChange: (e) => {console.log(e)},
            text: ""
        }
    ]
    const [fieldProperties, setFieldProperties] = useState(initialFieldProperties)

    function getFieldProperty(name) {
        for(let i = 0; i < fieldProperties.length; i++) {
            let fieldProperty = fieldProperties[i]
            if(fieldProperty.name === name) {
                return {fieldProperty, i}
            }
        }
        console.log(`Warrning ${name} not found`)
        return {}
    }

    function onFieldChange(e, name) {
        let {fieldProperty, i} = getFieldProperty(name)
        if(fieldProperty != null && i != null) {
            fieldProperties[i].text = e.target.value
            setFieldProperties(fieldProperties)
        }
    }
    fieldProperties.forEach((fieldProperty) => fieldProperty.handleChange = (e) => onFieldChange(e, fieldProperty.name))


    async function buttonPressed() {
        let password = getFieldProperty('password').fieldProperty.text
        let passwordConfirmation = getFieldProperty('password confirmation').fieldProperty.text
        setIsLoading(true)
        if((!password || !passwordConfirmation) || password !== passwordConfirmation) {
            showError("Passwörter sind nicht gleich")
        }
        try {
            password = password.trim()
            passwordConfirmation = passwordConfirmation.trim()
            
            let reason = `passreset.${code}`
            const response = await axios.post(BACKEND_URL+"/nounce/generate_nounce", { data: { reason } });
            const nounce = response.data.data.nounce || "+LctPbVmVnEt8F2Tv0Cfog==";
        
            const eky = CRYPTOK || "UwXPEH9gc54XkXKtr8J7nukInrHBrjz3P1Ty+Bv6QeA=";

            const key = enc.Base64.parse(eky);
            const iv = enc.Base64.parse(nounce || "");
            password = passwordConfirmation = AES.encrypt(password, key, {
                iv,
                mode: mode.CBC,
                padding: pad.Pkcs7
            }).toString();
            console.log("Token: "+code)
            const responsePasswordChange = await axios.post(BACKEND_URL+"/auth/reset-password", { 
                password: password, 
                passwordConfirmation: passwordConfirmation, 
                code: code
            });

            setIsDone(true)
            console.log(responsePasswordChange)
            console.log(responsePasswordChange.code)
        } catch(e) {
            console.log(e)
            alert("Fehler passiert: " +e)
        }
        setIsLoading(false)
    }

    return (
        <HomeLayout>
            <Title>
                Passwort zurücksetzen für Ihr Budget Drive Konto
            </Title>
            {code === null ? <p>Diese Seite erfordert einen Code zum Zurücksetzen des Passworts</p> : isLoading ? <p>Es ladet...</p> : 
                isDone ? <p>Versuchen Sie nun, sich in Budge Drive anzumelden.</p> :
                <div>
                    <center>
                        <p>Geben Sie eine neue Password an</p>
                    </center>
                    <Padding $top={topPaddingDifferentComponent}>
                        <MultiLineTextField FieldProperties={fieldProperties}/>
                    </Padding>
                    <Padding $top={topPaddingDifferentComponent}>
                        <Button onClick={buttonPressed} text={"Passwort zurücksetzen"}/>
                    </Padding>
                </div>
            }
        </HomeLayout>
    );
}

export default PasswordResetPage;