import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../styles/colors';
import Hamburger from 'hamburger-react'
import { useMediaQuery } from 'react-responsive';
import { STYLE_CONSTANTS } from '../../../styles/styleConstants';

const PositionDiv = styled.div`
  position: fixed;
  top: 0;
  height: ${STYLE_CONSTANTS.headerSize()};
  align-content: center;
  padding-right: 8px;
  right:0;
  z-index: 11;
`

const NavLinkHover = styled.a`
  text-decoration: none;
  padding: 12px 12px 12px 12px;
  color: black;
  &.active {
    color: ${COLORS.purple};
  }
  &:hover {
    background-color: ${COLORS.hoverWhiteSmoke};
  }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`
const Column = styled.div`
  position: fixed;
  top: ${STYLE_CONSTANTS.headerSize()};
  left: 50%;
  height: auto;
  width: 70vw;
  gap: 12px;
  padding: 12px 12px 12px 12px;
  transform: translate(-50%, -5%);
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  transition: opacity 250ms ease-out, transform 250ms ease-in;
  ${STYLE_CONSTANTS.headerStyleConstants}
  &.show {
    pointer-events: auto;
    transform: translate(-50%, 0);
    opacity: 1;
  }
`
const menuItems = [
    {
        link: "/",
        label: "Startseite"
    },
    {
        link: "/user-deletion-guide",
        label: "Konto Löschung"
    },
    {
        link: "/privacy-policy",
        label: "Datenschutz"
    },
    {
        link: "/about",
        label: "Über uns"
    }
  ]
const NavigationLinks = ({direction = 'row', isVisible, closeMenu}) => {
    const items = menuItems.map((item, i) => <NavLinkHover key={i} href={item.link} onClick={closeMenu}>{item.label}</NavLinkHover>)
    return (
        direction === 'row' 
        ?  <Row>
            {items}
        </Row>
        : <Column className={isVisible ? 'show' : ''}>
            {items}
        </Column>
    )
}

const BurgerMenu = ({menuOpen, toggleMenu, closeMenu}) => {
    return (
        <div>
            <Hamburger color={COLORS.purple} toggled={menuOpen} toggle={toggleMenu}/>
            <NavigationLinks isVisible={menuOpen} direction='column' closeMenu={closeMenu} />
        </div>
    )
}

function MobileNav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const isMobile = useMediaQuery({ query: STYLE_CONSTANTS.mediaQueryMaxWidth });

  return (
    <PositionDiv>
        {
            isMobile 
            ? <BurgerMenu menuOpen={menuOpen} toggleMenu={toggleMenu}/> 
            : <NavigationLinks closeMenu={closeMenu}/>
        }
    </PositionDiv>
  );
}

export default MobileNav;