import styled from "styled-components";
import { COLORS } from "./colors.js";
import { STYLE_CONSTANTS } from "./styleConstants.js";

const HomeLayout = styled.div`
  display: flex;
  padding: 0px 5vw;
  flex-direction: column;
  min-height: ${100-STYLE_CONSTANTS.headerSizeValue-STYLE_CONSTANTS.footerSizeValue}vh;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: space-between;
  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {
    width: 90vw;
    flex-direction: column;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.025rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  color: ${COLORS.purple};

  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.h1`
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.03rem;
  text-align: center;
  color: ${COLORS.purple};
  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {
    font-size: 0.9rem;
  }
`;

const BoldText = styled.p`
  font-weight: bold;
`;

export { Title, BoldText, Subtitle, Row, HomeLayout };
