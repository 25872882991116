import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { FEATURE_IMAGES } from "./imagesConstants";
import { faCar, faCirclePlay, faCoins, faDollarSign, faWrench } from "@fortawesome/free-solid-svg-icons";

export const FEATURES = {
    VehicleOverview: {
        title: `Fahrzeugverwaltung`,
        images: FEATURE_IMAGES.VehicleOverview,
        icon: faCar,
        shortDescription: `Übersicht über die Fahrzeuge und Fahrzeugfahrern.`,
        description: `
            Bevor weitere Fahrzeugfunktionen in der Budget Drive Mobile-App genutzt werden können, muss ein Fahrzeug registriert und ein Fahrer hinzugefügt werden.

            1. Die Startseite dient der Anzeige wichtiger Informationen, wie das aktuelle Fahrzeug und die in einem ausgewählten Zeitraum gefahrenen Kilometer.
            2. Auf der Fahrzeuge-Seite können Fahrer mehrere Fahrzeuge verwalten, einige davon im eigenen Besitz, andere, an denen sie zur Kostenteilung und Wartung eingeladen sind.
            3. Fahrer können hinzugefügt oder entfernt werden. Außerdem kann man mehrere Fahrer zu Fahrzeugverwaltern ernennen, die das Fahrzeug umfassend mitverwalten können.
            4. Fahrzeugverwalter können weitere Fahrer auf unterschiedliche Weisen einladen. Man kann bereits registrierte Fahrer über einen Link oder per E-Mail einladen oder Einzelbenutzer generieren, um nur dieses Fahrzeug zu fahren.
            5. Dokumentierte Fahrten können überblickt werden. Diese können weiterhin nützlich sein, um zum Beispiel herauszufinden, wer die letzten Fahrer waren und an welchem Datum gefahren wurde.
        `,
    },
    DocumentTrip: {
        title: `Fahrzeugnutzungerfassung`,
        images: FEATURE_IMAGES.DocumentTrip,
        icon: faCirclePlay,
        shortDescription: `Haben Sie eine Übersicht wer wie viel gefahren ist.`,
        description: `
            Die Hauptmethode zur Dokumentation der Fahrzeugnutzung ist die Kilometererfassung.

            1. Um zur Fahrten-Dokumentation zu navigieren, muss der große Play/Stop-Icon-Button in der unteren Leiste gedrückt werden.
            2. Benutzer können den Startkilometerstand manuell eingeben.
            3. Der Tachometer kann auch über die Live-Kameraansicht gescannt werden, was besonders nützlich für Fahrer ist, die Schwierigkeiten haben, auf ihrem Mobilgerät zu lesen oder zu tippen.
            4. Beim Beenden einer Fahrt kann die Fahrt anhand der aktuellen Kraftstoffpreise berechnet werden, dies ist geeignet für Benutzer, die nur sehr selten mitfahren.
            5. Wenn mehrere Fahrer an der Fahrt teilgenommen haben, kann die aktuelle Fahrt aufgeteilt werden.
            6. Die Dokumentation der Fahrten ergibt eine Übersicht über die Fahrzeugnutzung.
        `,

    },
    CreateOverviewExpense: {
        title: `Ausgabendokumentieren`,
        images: FEATURE_IMAGES.CreateOverviewExpense,
        icon: faDollarSign,
        shortDescription: `Erstellen und Übersicht der Ausgaben.`,
        description: `
            Der Hauptvorteil von Budget Drive ist die faire Aufteilung der Kosten für die Fahrer. Wenn jeder bei der Fahrzeugerhaltung auf gleiche Weise behandelt wird, sollte dies den Fahrern das Fahrzeug sorgfältig und regelmäßig zu warten anspornen, wie zum Beispiel: Tanken, Reparieren und Reinigen.

            1. Durch das Anklicken des 'Dollar-Zeichens' in der unteren Leiste können Benutzer zur Ausgaben-Dokumentationsseite navigieren.
            2. Die Person, welche die Ausgabe erstellt, wird als Zahlungsträger angenommen. Weitere Felder werden dynamisch für jeden Ausgabetyp angezeigt. Für eine Treibstoffausgabe bieten wir beispielsweise eine Durchschnittsberechnung des Treibstoffverbrauchs für eine präzisere Ausgabenberechnung.
            3. Es gibt eine Vielzahl vordefinierter Ausgabetypen, die eindeutige Eingabefelder und unterschiedliche Berechnungsmethoden bei der Kostenverteilung haben.
            4. Vergangene Ausgaben können überblickt und als Basis für die Ausgabenberechnung verwendet werden. Ausgaben, die noch nicht für Berechnungen verwendet wurden, können bearbeitet oder entfernt werden.
            5. Weitere Informationen zu einer vergangenen Ausgabe können detailliert angezeigt werden, wenn eine der vergangenen Ausgaben ausgewählt wird.
        `,

    },
    OverviewDepts: {
        title: `Schuldenüberischt`,
        images: FEATURE_IMAGES.OverviewDepts,
        icon: faCoins,
        shortDescription: `Haben Sie die Kontrolle über Ihr Geld.`,
        description: `
            Ausgaben sind bereit für die Berechnung, wenn eine bestimmte Kilometerzahl erreicht oder das Gültigkeitsdatum überschritten wurde.

            1. Berechnungen können unter den vergangenen Ausgaben gestartet werden.
            2. Es gibt zwei Methoden, um Ausgaben zu berechnen: entweder automatisch, bei der bestimmte Regeln je nach Ausgabenstatus angewendet werden, oder manuell, was nicht unbedingt die vergangene Fahrzeugnutzung berücksichtigt.
            3. Für die manuelle Berechnung kann entweder ein Zeitraum ausgewählt oder der Slider verwendet werden, um die Berechnung entsprechend anzupassen.
            4. Von der 'Ausgaben-Erstellungsseite' können Benutzer zur 'Offene Ausgaben Seite' navigieren, wo alle offenen Schulden in einem Überblick dargestellt werden.
            5. Jeder Fahrer mit offenen Schulden wird angezeigt. Offene Schulden können für datierte Zusammenbaut der Schulden erweitert werden.
            6. Der Schuldner muss seine Schulden als teilweise oder vollständig abbezahlen. Auf der anderen Seite muss der Ausgaben-Zahler bestätigen, dass er das Geld für diese Schulden erhalten hat.

            Hinweis: Wir verarbeiten keine Geldtransfers; dies geschieht direkt zwischen den Fahrern.
        `,

    },
    Reservation: {
        title: `Fahrzeugreservierung`,
        images: FEATURE_IMAGES.Reservation,
        icon: faCalendar,
        shortDescription: `Wissen sie, wer wann fahren möchte.`,
        description: `
            Oftmals möchten Fahrer das Auto mehrmals am Tag oder über mehrere Tage hinweg nutzen, beispielsweise für eine Reise. Deshalb ist es möglich, Reservierungen für das Fahrzeug hinzuzufügen, damit andere Fahrer die Verfügbarkeit oder Nichtverfügbarkeit des Fahrzeugs für bestimmte Zeiträume einsehen können.

            1. Wenn das Fahrzeug bald reserviert ist, kann eine Benachrichtigung diesbezüglich auf der Startseite gesehen werden.
            2. Reservierungen können für das spezifische Auto auf der Fahrzeuge-Übersichtsseite gestartet werden.
            3. Im eingebauten Kalender können Reservierungen überblickt werden.
            4. Durch Umschalten in den Bearbeitungsmodus können Reservierungen durch Anklicken der Daten erstellt oder bearbeitet werden.
        `,

    },
    Repair: {
        title: `Reparaturübersicht`,
        images: FEATURE_IMAGES.Repair,
        icon: faWrench,
        shortDescription: `Verpassen Sie nie einen Termin für die Autoreparatur.`,
        description: `
            Durch das Nichtdurchführen regelmäßiger Wartungen können enorme Kosten entstehen. Während einige Benutzer mehr über das Fahrzeug wissen, könnten andere dies nicht tun. Deshalb ermöglicht die Fahrzeugreparaturfunktion den Fahrern, sich über anstehende Wartungstermine zu verständigen und zu überblicken.

            1. Wenn ein bestimmter Schwellenwert für die Wartung erreicht ist, zeigt die Startseite an, dass überprüft werden muss, welche Teile gewartet werden müssen.
            2. Die Reparaturübersicht findet man unter der Fahrzeuge-Übersichtsseite für das spezifische Fahrzeug.
            3. Diese Reparaturübersicht zeigt, welche Teile gewartet werden müssen.
            4. Zudem können Benachrichtigungsregeln erstellt oder deaktiviert werden. Auch empfohlene Wartungsregeln können aktiviert werden.
            5. Die Wartungsregeln können spezifisch für das Fahrzeug angepasst werden und es kann festgelegt werden, welcher Fahrzeugteile zu welchem Zeitpunkt gewartet werden sollten. Dies kann entweder nach Ablauf bestimmter Monate oder nach einer bestimmten Anzahl gefahrener Kilometer erfolgen.

            Hinweis: Wir übernehmen keine Verantwortung für die unangemessene Einrichtung dieser Regeln. Bitte konsultieren Sie einen Mechaniker, um die Regeln entsprechend Ihrem Fahrzeugmodell anzupassen.
        `,

    },
}