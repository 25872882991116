import EmailConfirmationPage from "./components/pages/EmailConfirmationPage";
import HomePage from "./components/pages/HomePage";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PasswordResetPage from "./components/pages/PasswordResetPage";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import UserDeletionGuidePage from "./components/pages/UserDeletionGuidePage";
import { useEffect } from "react";
import AboutPage from "./components/pages/AboutPage";
import AppLayout from "./components/AppLayout";

function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Router>
      <Routes>
        <Route element={<AppLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/password-reset" element={<PasswordResetPage />} />
          <Route
            path="/email-confirmation"
            element={<EmailConfirmationPage />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/user-deletion-guide"
            element={<UserDeletionGuidePage />}
          />
          <Route path="/login" element={<Navigate to="/" />} />
          <Route path="/vehicles" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
