import styled from "styled-components";
import { COLORS } from "../../styles/colors";

const StyledButton = styled.button`
 background-color: ${COLORS.purple};
 color: white;
 min-height: 70px;
 min-width: 280px;
 border-radius: 15px;
 border: 0px;
 box-shadow:  0px 6px 6px rgba(0, 0, 0, 0.25); 
 display: flex;
 justify-content: center;
 align-items: center;
 cursor: pointer;
 
 &:hover {
    background-color: ${COLORS.purpleHover}; 
 }
 &:active {
    background-color: ${COLORS.purple};
    border-right: 1px solid ${COLORS.grey};
    border-bottom: 1px solid ${COLORS.grey};
 }
`;

export default function Button({onClick, text}) {
    return (
      <StyledButton onClick={onClick}>
        { text ?? "Click me"}
      </StyledButton>
    );
   }