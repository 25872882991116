import React from 'react';
import { HomeLayout, Title } from '../../styles/StyledElements';

function EmailConfirmationPage() {
    return (
        <HomeLayout>
            <Title>
                E-Mail-Bestätigung für Budget Drive
            </Title>
                <p>
                Ihre E-Mail wurde bestätigt und Sie können sich nun in der Budget Drive App anmelden.
                </p>
                <p>
                    Ihre Budget Drive Service.
                </p>
        </HomeLayout>
    );
}

export default EmailConfirmationPage;