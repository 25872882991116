import styled from "styled-components";


const ResponsiveImage = styled.img`
  max-height: 25vh; // Set maximum height to 50% of the viewport height
  width: 100%; // Make sure the image takes the full width of its container
  object-fit: contain; // Maintain the aspect ratio of the image
`;

export default function Logo() {
    const appLogo = "logo512.png";
    return (
        <ResponsiveImage alt="Budget Drive logo" src={ appLogo }/>
    )
}