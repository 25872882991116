import styled from "styled-components";
import { COLORS } from "../../styles/colors";

const StyledBackground = styled.div`
 border-radius: 15px; 
 background-color: ${COLORS.beige}; 
 border-spacing: 0; 
 padding: 15px;
 display: inline-block;
 justify-content: center;
 align-items: center;
 min-height: 45px;
 min-width: 375px;
`;
const Divider = styled.div`
 border-bottom: 1px solid #c5c5c7;
 width: 100%;
 height: 3.5px;
`;
const StyledInput = styled.input`
 background-color: transparent;
 border: none;
 outline: none;
 width: 100%;
 &:focus {
  outline: none;
 }
`;
/*
fieldProperty has the fellowing values
{
    {
        name:"password",
        type: "password",
        placeholder: "Passwort",
        handleChange: (e) => {console.log(e)}
    },
}
*/
export default function MultiLineTextField({FieldProperties}) {  
    if(FieldProperties.length === 0) {
        return (
            <div></div>
        )
    }  
    let lines = []
    for (let i = 0; i < FieldProperties.length; i++) {
        let fieldProperty = FieldProperties[i]
        let isLastEntry = i === FieldProperties.length - 1
        lines.push(
            <StyledInput key={`input${i}`} name={fieldProperty.name} type={fieldProperty.type} placeholder={fieldProperty.placeholder}  onChange={fieldProperty.handleChange} />
        )
        if(!isLastEntry) {
            lines.push(<Divider key={`divider${i}`} />)
        }
    }
    return (
        <StyledBackground>
            {lines}
        </StyledBackground>
    ); 
}