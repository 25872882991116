export const STYLE_CONSTANTS = {
    mediaQueryMaxWidth: '(max-width: 768px)',
    scrollDuration: 300,
    footerSizeValue: 3,
    headerSizeValue: 10,
    headerSizeUnit: "vh",
    headerSize: (addedValue = 0) => `${addedValue+STYLE_CONSTANTS.headerSizeValue}${STYLE_CONSTANTS.headerSizeUnit}`,
    headerStyleConstants: `
        z-index: 10;
        background-color: hsla(0, 0%, 96.1%, 0.3);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    `
}