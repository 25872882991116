import React, { useCallback, useEffect, useRef } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Fade from "embla-carousel-fade";

import { DotButton, useDotButton } from "./EmblaCarouselDotButton";

import "../../../styles/embela.css";
import styled from "styled-components";
import { useAutoplayOnViewport } from "./VideoPlaybackObserver";
import { useMediaQuery } from "react-responsive";
import { STYLE_CONSTANTS } from "../../../styles/styleConstants";

const VerticalPadding = styled.div`
  width: 100%;
  align-content: center;
  padding: 6vh 0px;
`;

const EmblaCarousel = (props) => {
  const progressRef = useRef();
  const { slides } = props;

  const isMobile = useMediaQuery({ query: STYLE_CONSTANTS.mediaQueryMaxWidth });
  const [emblaRef, emblaApi] = useEmblaCarousel({ 
    loop: true,
    watchDrag: isMobile
  }, [Fade()]);

  const autoplayTimeout = useRef(null);

  const { carouselRef, isInView } = useAutoplayOnViewport({
    root: null,
    rootMargin: "0px",
    threshold: 1.0, // Play when the carousel is fully visible
  });

  const autoplay = useCallback(() => {
    if (emblaApi && isInView ) {
      autoplayTimeout.current = setTimeout(() => {
        emblaApi.scrollNext();
      }, 4000);
    }
  }, [emblaApi, isInView ]);

  const handleSlidesChanged = useCallback(() => {
    clearTimeout(autoplayTimeout.current);

    autoplay();
    progressRef.current.classList.remove("progress-reset");
    void progressRef.current.offsetWidth;
    progressRef.current.classList.add("progress-reset");
  }, [autoplay]);

  useEffect(() => {
    if (!emblaApi) return;

    autoplay();
    emblaApi.on("select", handleSlidesChanged);

    return () => {
      clearTimeout(autoplayTimeout.current);
      emblaApi.off("select", handleSlidesChanged);
    };
  }, [emblaApi, handleSlidesChanged, autoplay]);

  
  useEffect(() => {
    if (isInView) {
      progressRef.current.classList.remove("progress-paused");
      progressRef.current.classList.add("progress-reset");
    } else {
      progressRef.current.classList.remove("progress-reset");
      progressRef.current.classList.add("progress-paused");
    }
  }, [isInView]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

    

  return (
    <VerticalPadding>
      <div ref={carouselRef} className="embla">
        <div className="embla__viewport" ref={emblaRef}>
          <div className="embla__container">
            {slides.map((picturePath, i) => (
              <div className="embla__slide" key={picturePath}>
                <img 
                  className="embla__slide__img"
                  src={picturePath}
                  alt="Your alt text"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="embla__controls">
          <div className="embla__dots">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={"embla__dot".concat(
                  index === selectedIndex ? " embla__dot--selected" : ""
                )}
              />
            ))}
          </div>
        </div>
        <center>
          <div className="loading-bar">
            <div ref={progressRef} className="progress progress-reset"></div>{" "}
          </div>
        </center>
      </div>
    </VerticalPadding>
  );
};

export default EmblaCarousel;
