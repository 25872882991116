const phoneImagesFolder = "assets/PhoneImages/"
const images = {
    Home : phoneImagesFolder + "Home.png",
    HomePageWithReservation : phoneImagesFolder + "HomePageWithReservation.png",
    DocumentTrip : phoneImagesFolder + "DocumentTrip.png",
    DocumentCamera : phoneImagesFolder + "DocumentCamera.png",
    DocumentTripStop : phoneImagesFolder + "DocumentTripStop.png",
    DocumentTripDivision : phoneImagesFolder + "DocumentTripDivision.png",
    ExpenseCreation : phoneImagesFolder + "ExpenseCreation.png",
    Expenses : phoneImagesFolder + "Expenses.png",
    ExpenseTypes : phoneImagesFolder + "ExpenseTypes.png",
    ExpenseDetail : phoneImagesFolder + "ExpenseDetail.png",
    DeptCalculationSelection : phoneImagesFolder + "DeptCalculationSelection.png",
    DeptManualCreation : phoneImagesFolder + "DeptManualCreation.png",
    Depts : phoneImagesFolder + "Depts.png",
    DeptDetail : phoneImagesFolder + "DeptDetail.png",
    RepairAllNotifications : phoneImagesFolder + "RepairAllNotifications.png",
    RepairRules : phoneImagesFolder + "RepairRules.png",
    RepairRuleCreation : phoneImagesFolder + "RepairRuleCreation.png",
    Reservation : phoneImagesFolder + "Reservation.png",
    ReservationCreation : phoneImagesFolder + "ReservationCreation.png",
    Vehicles : phoneImagesFolder + "Vehicles.png",
    VehicleDrivers : phoneImagesFolder + "VehicleDrivers.png",
    VehicleDriverInvitation : phoneImagesFolder + "VehicleDriverInvitation.png",
    VehicleTrips : phoneImagesFolder + "VehicleTrips.png",
}
export const FEATURE_IMAGES = {
    RandomPages: [
        images.Home,
        images.DocumentCamera,
        images.VehicleTrips,
        images.ExpenseTypes,
        images.Depts
    ],
    VehicleOverview: [
        images.Home,
        images.Vehicles,
        images.VehicleDrivers,
        images.VehicleDriverInvitation,
        images.VehicleTrips,
    ],
    DocumentTrip: [
        images.Home,
        images.DocumentTrip,
        images.DocumentCamera,
        images.DocumentTripStop,
        images.DocumentTripDivision,
        images.VehicleTrips,
    ],
    CreateOverviewExpense: [
        images.Home,
        images.ExpenseCreation,
        images.ExpenseTypes,
        images.Expenses,
        images.ExpenseDetail,
    ],
    OverviewDepts: [
        images.Expenses,
        images.DeptCalculationSelection,
        images.DeptManualCreation,
        images.ExpenseCreation,
        images.Depts,
        images.DeptDetail,
    ],
    Reservation: [
        images.HomePageWithReservation,
        images.Vehicles,
        images.Reservation,
        images.ReservationCreation,
    ],
    Repair: [
        images.Home,
        images.Vehicles,
        images.RepairAllNotifications,
        images.RepairRules,
        images.RepairRuleCreation,
    ]
}















