
import { v4 as uuidv4 } from 'uuid';
import { BACKEND_URL } from './EnvironmentVariables';
const getOS = () => {
  const userAgent = window.navigator.userAgent;
  const osList = [
    { regex: /Windows NT 10.0/, name: 'Windows 10' },
    { regex: /Windows NT 6.2/, name: 'Windows 8' },
    { regex: /Windows NT 6.1/, name: 'Windows 7' },
    { regex: /Windows NT 6.0/, name: 'Windows Vista' },
    { regex: /Windows NT 5.1/, name: 'Windows XP' },
    { regex: /Windows NT 5.0/, name: 'Windows 2000' },
    { regex: /Mac OS X/, name: 'macOS' },
    { regex: /Linux/, name: 'Linux' },
    { regex: /Android/, name: 'Android' },
    { regex: /iOS|iPhone|iPad|iPod/, name: 'iOS' },
  ];

  for (let i = 0; i < osList.length; i++) {
    if (osList[i].regex.test(userAgent)) {
      return osList[i].name;
    }
  }
  return 'Unknown';
};

const getUrlParameters = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const params = {};

  for (const [key, value] of urlParams) {
    params[key] = value;
  }

  return params;
};


const getSessionId = () => {
  const storedId = localStorage.getItem('sessionId');
  if(storedId) return storedId
  
  const newId = uuidv4()
  localStorage.setItem('sessionId', newId);
  return newId;
};



export const trackPageLoad = async (event) => {
  const url = window.location.href;
  const params = getUrlParameters();
  const os = getOS();
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  const sessionId = getSessionId();
  const body = {
    url,
    params,
    os,
    screenWidth,
    screenHeight,
    viewportWidth,
    viewportHeight,
    sessionId,
    event
  }
    //console.log(body)
  try {
      await fetch(`${BACKEND_URL}/website-statistics`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      });
  } catch(_) {
    //Its a try does not have to work
  }
};