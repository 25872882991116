import React from "react";
import { BoldText, Row, Title } from "../../../styles/StyledElements";
import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";
import EmblaCarousel from "../../widgets/carousel/EmbelaCarousel";
import styled from "styled-components";
import { FEATURE_IMAGES } from "../../../data/imagesConstants";
import { STYLE_CONSTANTS } from "../../../styles/styleConstants";
import { trackPageLoad } from "../../../utils/tracking";

const PlatformRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {    
    .button {
      max-width: 35vw;
    }
    .button-title {
      font-size: 0.6rem;
    }
    .button-store-name {
      font-size: 0.9rem;
    }
  }
`;
const LeftPadding = styled.div`
  padding-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {
    padding-left: 0;
  }
`
const Column = styled.div`
  flex: 2; /* Take up 2/3 of the row's space */
`;
const CarouselContainer = styled.div`
  flex: 1; /* Take up 1/3 of the row's space */
`;
const Text = styled.p`
  text-align: justify;
`;

const TopPadding = styled.div`
  height: 10vh;
  width: 100%;
  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {
    height: 0;
  }
`
const Spacer = styled.div`
  height: 10vh;
  width: 100%;
  @media ${STYLE_CONSTANTS.mediaQueryMaxWidth} {
    height: 0;
  }
`

function LandingPage() {
  const androidAppLink =
    "https://play.google.com/store/apps/details?id=tb.budget.drive";
  const iosAppLink = "https://apps.apple.com/app/budget-drive/id6499519542";

  return (
    <Row style={{paddingTop: "2vh"}}>
      <Column>
        <LeftPadding>
          <Text>Professionelle Fahrzeugfinanzkontrolle.</Text>
          <TopPadding/>
          <Title>{"Budget Drive"}</Title>
          <Text>Kostenverteilung einfach und mühelos!</Text>
          <Text>Unsere Kunden haben bis zu 40% ihrer Ausgaben reduziert.</Text>
          <BoldText>Kostenlos für Erstnutzer!</BoldText>
          <Spacer></Spacer>
          <Title>Jetzt Herunterladen:</Title>
          <PlatformRow>
            <div onClick={() => { trackPageLoad("APP_STORE_OPENED"); }}>
              <AppStoreButton
                className={"button"} 
                title="Herunterladen von"
                url={iosAppLink}
                theme={"light"}
                />
            </div>
            <div onClick={() => { trackPageLoad("PLAY_STORE_OPENED"); }}>
              <GooglePlayButton
                className={"button"} 
                title="Erhältlich bei"
                url={androidAppLink}
                theme={"light"}
                />
            </div>
          </PlatformRow>
        </LeftPadding>
      </Column>
      <CarouselContainer>
        <EmblaCarousel slides={FEATURE_IMAGES.RandomPages} />
      </CarouselContainer>
    </Row>
  );
}

export default LandingPage;
