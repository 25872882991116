import React from 'react';
import { Subtitle, Title } from '../../styles/StyledElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

const StyledImage = styled.img`
  height: 25vh;
  width: auto; // Optional, to maintain aspect ratio
  object-fit: cover; // Optional, to ensure the image covers the entire height
`;

const Text = styled.p`
    text-align: center;
`

class AboutPage extends React.Component {
    componentDidMount() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    entry.target.classList.add('show')
                } 
                // else {
                //     entry.target.classList.remove('show')
                // }
            })
        })
        const hiddenElements = document.querySelectorAll('.hidden')
        hiddenElements.forEach((e) => observer.observe(e))
    }

    render() { 
        const email = "service@budget-drive.com"
        const profilPic = "assets/ProfilPhoto.jpg"
        const centerStyle = {marginLeft: "auto", marginRight: "auto",  display: "block", paddingTop: "30px"}
        return (
            <div>
                <section className={"hidden"}>
                    <Title>{"Über uns"}</Title>
                    <StyledImage src={profilPic} alt="lächelnder glücklicher Entwickler"/>
                    
                    <Text>
                        Unabhängiger Entwickler: 
                    </Text>
                    <Text>
                        Balint Taschner-Berenguer Msc
                    </Text>
                    <Text>
                        von Österreich
                    </Text>
                </section>
                <section>
                    <Subtitle>{"Kontaktieren Sie uns"}</Subtitle>
                    <Text>
                        Für weitere Hilfe kontaktieren Sie bitte unser Service-E-Mail:
                    </Text>
                    <a href={`mailto:${email}?subject=Liebe%20Budget-Drive`}>
                        { email }
                    </a>
                </section>
                <section>
                    <Subtitle>{"Soziale Medien"}</Subtitle>
                    <Text>
                        Für Updates folgen Sie unseren Social Medias.
                    </Text>
                    <table>
                        <tbody>
                            <tr>
                                <th className="image hidden" style={centerStyle}>
                                    <a href="https://www.youtube.com/@budget-drive" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faYoutube} style={{ fontSize: '50px' }} color="#ff0000"/>
                                    </a>
                                </th>
                                <th style={{ padding: `0 5vw` }}></th>
                                <th className="image hidden" style={centerStyle}>
                                    <a href="https://www.instagram.com/budgetdrive/" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faInstagram} style={{ fontSize: '50px' }} color="#000000"/>
                                    </a>
                                </th>
                            </tr>
                        </tbody> 
                    </table>
                </section>
            </div>
        );
    }
}

export default AboutPage;